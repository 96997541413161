<template>
  <div class="payment-container">
    <div class="header">
      {{ $t('submitInspectionInformation') }}
    </div>

    <div class="inspection-detail">
      <div class="text">
        {{ $t('verificationCode') }}
      </div>

      <div style="display: flex;">
        <div
          class="password-input"
        >
          <password-input
            v-model="verificationCode"
            :mask="false"
            :focused="showKeyboard"
            :length="4"
            :gutter="15"
            @input="onInput"
            @focus="showKeyboard = true"
          />
        </div>

        <Button
          class="smaller"
          type="danger"
          :disabled="isCountDown"
          @click="sendOTPCode"
        >
          {{ $t('send') + (isCountDown ? ` (${countdownVal})` : '') }}
        </Button>
      </div>

      <div v-if="isCountDown">
        <p class="text desc">
          {{ $t('otpDesc', {mobileNo: mobileNo}) }}
        </p>
      </div>

      <number-keyboard
        v-model="verificationCode"
        :show="showKeyboard"
        @blur="showKeyboard = false"
      />

      <div class="footer fu-fixed">
        <Button
          type="danger"
          @click="verifyOTPCode"
        >
          <p>
            {{ $t('submit') }}
          </p>
        </Button>
      </div>
    </div>

  </div>
</template>

<script>

import Dao from '@/utils/dao'
import { PasswordInput, NumberKeyboard, Button
  //  Field
} from 'vant'
import {
  verifyOTPCode,
  sendOTPCode, appendInspection } from './api'

export default {
  name: 'Payment',
  components: {
    PasswordInput,
    NumberKeyboard,
    Button
  },
  data() {
    return {
      mobileNumber: '',
      verificationCode: '',
      showKeyboard: false,
      isCountDown: false,
      countdownDefaultVal: 60,
      countdownVal: 60
    }
  },
  computed: {
    expiryDate() {
      return new Date().getTime() + 1000 * 60 * 60 * 24
    },
    mobileNo() {
      return '+84 ' + Dao.get('inspectionInfo').shareInspectionInfo?.inspectionPolicyHolderInfo?.policyHolderMobile || ''
    }
  },
  methods: {
    async sendOTPCode() {
      var inspectionInfo = await Dao.get('inspectionInfo')
      const token = Dao.get('inspectionToken')
      const params = {
        orderID: inspectionInfo.shareInspectionInfo.orderUid,
        type: 'INSPECTION'
      }

      this.isCountDown = true
      const res = await sendOTPCode(params, token)
      if (res.code == 200) {
        this.countdown()
      } else {
        this.isCountDown = false
        this.$toast.fail(this.$t('register.thCodeFail'))
      }
    },
    countdown() {
      this.countdownText = this.countdownVal //  + ' S'
      this.countdownVal--

      // 倒计时结束
      if (this.countdownVal === 0) {
        this.isCountDown = false
        this.countdownText = this.sendDefaultText
        this.countdownVal = this.countdownDefaultVal

        return
      }

      setTimeout(this.countdown, 1000)
    },
    async verifyOTPCode() {
      var inspectionInfo = await Dao.get('inspectionInfo')
      const token = Dao.get('inspectionToken')

      const params = {
        orderID: inspectionInfo.shareInspectionInfo.orderUid,
        verificationCode: this.verificationCode,
        type: 'INSPECTION'
      }

      const res = await verifyOTPCode(params, token)
      if (!res || res.code != 200) {
        this.$toast(this.$t('register.thCodeInvalid'))
      } else {
        this.submitInspectionData()
      }
    },
    async submitInspectionData() {
      const token = Dao.get('inspectionToken')
      const inspectionData = Dao.get('inspectionAppendForm')
      const res = await appendInspection(inspectionData, token)

      if (!res || res.code !== 200) { return }

      Dao.set('inspectionApplicationForm', null)

      if (!res) { return } else {
        this.$router.push('/inspection/orderDetail/?token=' + token)
      }
    }
  }
}
</script>

  <style lang="scss" scoped>
  .payment-container {
    background-color: #fbfbfb;
    height: 500px;

    .hint {
      font-size: 15px;
      margin: 10px 0px 15px 0px;
    }

    .header {
      padding: 0 20px;
      height: 50px;
      line-height: 45px;
      background-color: #D90109;
      font-size: 0.4rem;
      text-align: center;
      color: white;
  }

  .input-field {
    min-height: 1.17333rem;
    background-color: #FFFFFF;
    box-shadow: 0px 0.42px 1.6px rgba(0, 0, 0, 0.25);
    border-radius: 0.45333rem;
    padding: 0 10px;
    margin-top: 20px;
  }

  .inspection-detail {
    padding: 15px;
    font-size: 14px;

    .hint {
      font-size: 23px
    }

    .text {
      margin: 15px 0px;
    }

    .desc {
      text-align: center;
      font-size: 12px;
      padding: 0px 70px;
    }

    .van-password-input__item {
      box-shadow: 0px 0.4px 1.6px rgba(0, 0, 0, 0.25);
    }
  }

  .logo {
    text-align: center;
  }

  .van-button{
      background: #C82A21;
      box-shadow: 0px 0.714988px 2.85995px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
      height: 47px;
      line-height: 47px;
      text-align: center;
      width: 28%;
      overflow: hidden;
      .van-button__text{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        color: #FFFFFF;
      }
    }

    .footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px 40px;
  background: #fff;
  box-shadow: 0px -2.5px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  .van-button {
    // background: #C82A21;
    box-shadow: 0px 0.714988px 2.85995px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    height: 47px;
    line-height: 47px;
    text-align: center;
    width: 100%;
    overflow: hidden;
    .van-button__text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      // color: #FFFFFF;
    }
  }

  .van-button + .van-button {
    margin-left: 10px;
  }
  }

  .van-button--block {
    width: 100%;
  }

  .password-input {
      width: 70%;
    }

  }

  </style>

